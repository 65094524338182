import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from "../components/Layout"
//import BlogImageLoader from '../components/BlogRollImageLoader'

export default function Blog({ data }) {
  // console.log('blog post info:', data.allFile.edges[4].node.childImageSharp.fluid);
  // console.log(data.allFile.edges.find(n => {
  //   return n.node.relativeDirectory.includes('hello-world');
  // }))

  return (
    <Layout>
      <div className='flex flex-column justify-center items-center tc min-vh-100'>
        <h1 className='mv0'>
          Blog
        </h1>
        
        <h4 className='ma1'>{data.allMarkdownRemark.totalCount} Posts</h4>
        
        <div className='flex flex-wrap justify-around items-center w-100'>
          {data.allMarkdownRemark.edges.map(({ node }) => {
           
            // Creating imageNode, which finds the node that includes the blog title (blog titles are the src path) 
            let imageNode = data.allFile.edges.find(n => {
              return n.node.relativeDirectory.includes(node.frontmatter.title.toLowerCase().replace(/\s/g, '-'));
            })
            

            return(
              <div className='w-80 w-30-ns' key={node.id}>
              
                <article className="bg-white center w-100 ba b--black-10 mv4 shadow-1 dim ">
                  <div className="pv2 ph3">
                    <h1 className="f6 ttu tracked">{node.frontmatter.title}</h1>
                  </div>
                  <Link
                  to={`/blog/`+node.frontmatter.slug}>
                    <Img className='blog-roll-image db' fluid={imageNode.node.childImageSharp.fluid} />
                
                  </Link>
                  <div className="pa3">
                    <Link
                      to={`/blog/`+node.frontmatter.slug} className="link lh-title">{node.frontmatter.description}
                    </Link>
                    <small className="gray db pv2"><time>{node.frontmatter.date}</time></small>
                  </div>
                </article>
                

                {/* <Link
                  to={`/blog/`+node.frontmatter.slug}>
                  <h3 className='ma1'>
                    {node.frontmatter.title}{" "}
                    <span>
                      — {node.frontmatter.date}
                    </span>
                  </h3>
                  <p>{node.excerpt}</p>
                </Link> */}
                
              </div>
            )
           
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id  
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            slug
            description
          }
        }
      }
    }
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {regex: "/(blog)/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
        }
      }
    }
  }
`